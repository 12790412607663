
// node --max_old_space_size=2048 ./node_modules/@angular/cli/bin/ng build --prod --build-optimizer --aot

const apiDomain = 'https://staging.homitv.com';
const appDomain = 'https://staging.homitv.com';
const webRTCHost = 'webrtc.vplayed.com';

export const environment = {
  production: true,
  userApiUrl: `${apiDomain}/users/api/`,
  apiUrl: `${apiDomain}/medias/api/`,
  userActionApiUrl: `${apiDomain}/useractions/api/`,
  paymentApiUrl: `${apiDomain}/payments/api/`,
  notificationApiUrl: `${apiDomain}/notifications/api/`,
  commonApiUrl: `${apiDomain}/common/api/`,
  searchApiUrl: `${apiDomain}/search/elastic/`,
  analyticsApiUrl: `${apiDomain}/analytics/api/`,
  domain: appDomain,
  webRTCBroadcastDomain: `https://${webRTCHost}:5443/`,
  webRTCSocketUrl: `wss://${webRTCHost}:5443/WebRTCAppEE/websocket`,
  socketUrl: 'wss://admin.vplayed.demo.contus.us/ws/',
  encryption_key: 'vplay@contus',
  GoogleOAuthClientId:
      '136707794634-8hpepo0snoqdj0arn1bghulhqva7qton.apps.googleusercontent.com',
  FacebookAppId: '399586185313026',
  googleRecaptcha: '6Ld6TtQZAAAAAEXyWthaQ3l313Qpozdmv8djq6dq',
  defaultImage: 'assets/images/blank_new.png',
  crypto_iv: 'a32b97d84acba36667c7709de74fa510',
  crypto_key:
      '2f00f5fbea3c1c77e8d3e5536021fc1d46e803670879e01e9de64b387ea4dc68',
  CURRENCY_SYMBOL: '$',
  STRIPE_PUBLIC_KEY: ''
};

