import { Component, OnInit, AfterViewInit, Input, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Meta } from '@angular/platform-browser';
import disableScroll from 'disable-scroll';
import { trigger, transition, style, animate } from '@angular/animations';
import videojs from '../../../../../src/assets/js/play.es.js';
import {OwlCarousel} from 'ngx-owl-carousel';
import {DeviceDetectorService} from 'ngx-device-detector';

declare var $: any;

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, AfterViewInit {
    @Input() banner;
    @ViewChild('owlElement') owlElement: OwlCarousel;
    bannerData = [];
    trailerHoverTimer;
    video: any;
    slidItemClass = false;
    player: any;
    timeout;
    videoPlayHoverState = true;
    timeouts = [];
    cipherText: any;
    encrypted: any;
    decrypted: any;
    interVal: any;
    plainText: any;
    index = 0;
    show_mute = false;
    previousData: any;
    playerInterval = [];
    track_player_status: string;
    prev_index: number;
    change_image: NodeJS.Timer;
    showTxt = true;
    screenWidth: number;

    constructor(
        private router: Router,
        public deviceService: DeviceDetectorService
    ) {

        this.screenWidth = window.innerWidth;
    }

    sliderOptions = {
        nav: true, autoplay: false, loop: false, rewind: true, dots: true, responsive: {'0': {items: 1}, '600': {items: 1, nav: false}, '1000': {items: 1}}, onChanged: (e) => {

            this.index = this.owlElement.$owlChild.currentSlideIndex;
            if (this.index < e.item.count) {
                clearTimeout(this.change_image);
                if (this.player) {
                    this.player.pause();
                }
                const bannerSlide = document.getElementById('slider_' + this.banner[this.index].slug);
                bannerSlide.style.display = 'block';
                if (this.banner[this.index].trailer_hls_url !== null && this.banner[this.index].trailer_hls_url !== '' && this.banner[this.index].trailer_status === 'Complete') {
                    this.playTrailer(this.banner[this.index]);
                    const vp = videojs(this.banner[this.index].slug).player();
                    vp.muted(!this.show_mute);
                    this.previousData = this.banner[this.index];
                } else {
                    this.change_image = setTimeout(function () {
                        const owl = $('.owl-carousel');
                        owl.trigger('next.owl.carousel');
                        const bannerSlide = document.getElementById('slider_' + this.banner[this.index].slug);
                        bannerSlide.style.display = 'block';
                    }, 4000);
                }
            }

        }
    };

    ngOnInit() {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
        this.bannerData = this.banner;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // Update screenWidth with the new window width
        this.screenWidth = window.innerWidth;
        // Call a method or perform any logic based on the screen size change
        this.handleScreenSizeChange();
    }

    handleScreenSizeChange() {
        // Implement logic based on screen size change
        if (this.screenWidth < 768) {
            setTimeout(() => {
                this.showTxt = false;
            }, 5000);
           
        } else {
            this.showTxt = true;
            
        }
    }

    testClick() {
        console.log("test click-->")
    }

    // onVideoPlaying() {
    //   console.log("VIDEO PLAYING")
    //   this.videoPlaying = false
    // }
    ngAfterViewInit() {
        // this.playTrailer(this.bannerData[this.index]);
    }

    // Banner click
    onBannerClick(video: string) {
        console.log ("Banner Clicked --")
        this.router.navigate(['video/' + video]);
    }

    muteUnmuteClick(type, video) {
        this.show_mute = !this.show_mute;
        const vp = videojs(video).player();
        vp.muted(type);

        console.log("Mute clicked --", this.show_mute)
    }

    /// trailer video

    playerConfiguration(videoElem) {
        this.player = videojs(videoElem, {
            autoplay: true,
            controls: false,
            preload: true,
            bigPlayButton: false,
            muted: true
        });
    }

    playTrailer(video) {
        // if (this.videoPlayHoverState) {
        const videoSelector = video.slug;
        const getVideo = document.getElementById(videoSelector);
        if (getVideo != null) {
            if (video.trailer_hls_url !== null && video.trailer_hls_url !== '' && video.trailer_status === 'Complete') {
                this.playerConfiguration(getVideo);
                // this.trailerHoverTimer = setTimeout(function () {
                this.player.src({
                    src: video.trailer_hls_url,
                    type: 'application/x-mpegURL'
                });
                //  getVideo.parentElement.classList.remove('trailer-ended');
                this.player.load();
                this.player.on('play', function (e) {
                   
                    this.track_player_status = 'play';
                    this.videoPlayHoverState = false;
                    this.handleScreenSizeChange();
                }.bind(this));
                if (video.trailer_hls_url !== null && video.trailer_hls_url !== '' && video.trailer_status === 'Complete') {
                    this.player.on('ended', () => {
                    
                        this.videoPlayHoverState = true;
                        this.player.pause();
                        if (this.track_player_status === 'play') {
                            const owl = $('.owl-carousel');
                            owl.trigger('next.owl.carousel');
                            this.showTxt = true;
                            //  getVideo.parentElement.classList.add('trailer-ended');
                            this.track_player_status = 'stop';
                        }
                    });
                }
                // }.bind(this), 1000, getVideo, video);
                // this.timeouts.push(this.trailerHoverTimer);
            }
        }
        // }
        setTimeout(() => {
            const bannerSlide = document.getElementById('slider_' + videoSelector);
            bannerSlide.style.display = 'none';
        }, 4000);
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
